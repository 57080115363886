import React, { useState, useEffect } from 'react';
import useOnView from 'react-useonview';

const Typewriter = (props) => {

  const [visible, setVisible] = useState(false);
  const trigger = useOnView(() => setVisible(true));
  const [textCounter, setTextCounter] = useState(0);
  const [textWriter] = useState([props.text[0]]);

  if(visible){
    textCounter < props.text.length && setTimeout(() => setTextCounter(textCounter + 1), 500);
  }
  useEffect(() => {
    if(textCounter > 0){
      textWriter.push(props.text[textCounter]);
    }
  });

  return(
    <div className={'typewriter' + (visible?' typewriter--active':'')} ref={trigger}>
      {textWriter}
    </div>
  )
};

export default Typewriter