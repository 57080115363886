import React, { useState } from 'react';
import useOnView from 'react-useonview';

const Chart = (props) => {

  const [visible, setVisible] = useState(false);
  const trigger = useOnView(() => setVisible(true));
  const [counter, setCounter] = useState(0);

  if(visible){
    counter < 100 && setTimeout(() => setCounter(counter + 1), 48);
  }

  return(
    <div className={'chart' + (visible?' chart--active':'')} ref={trigger}>
      <svg viewBox="0 0 36 36" className="chart__svg" alt="Page Speed 100% - Desktop" title="Page Speed 100% - Desktop">
        <path className="chart__bg"
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
          />
        <path className="chart__circle"
          strokeDasharray="100, 100"
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
          />
        <text x="18" y="20.35" className="chart__percentage">{counter}%</text>
      </svg>
    </div>
  )
};

export default Chart