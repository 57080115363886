import React, { useState } from 'react';
import useOnView from 'react-useonview';

const BoxAnimation = (props) => {

  const [visible, setVisible] = useState(false);
  const trigger = useOnView(() => setVisible(true));

  return(
    <div className={'box-animation ' + (props.boxClass) + (visible?' ' + (props.boxClass) + '--active':'')} ref={trigger}>
      {props.children}
    </div>
  )
};

export default BoxAnimation