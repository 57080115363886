import React, { useState } from 'react';
import useOnView from 'react-useonview';

import PhoneEn from "../icons/phone_en.svg";
import PhoneDe from "../icons/phone_de.svg";
import PhoneEs from "../icons/phone_es.svg";

const Phone = (props) => {

  const [visible, setVisible] = useState(false);
  const trigger = useOnView(() => setVisible(true));

  return(
    <>
      <div className={'iphone' + (visible?' iphone--active':'')} ref={trigger}>
        {props.en?<PhoneEn alt="Mockup - iPhone" title="Mockup - iPhone"/>:''}
        {props.de?<PhoneDe alt="Mockup - iPhone" title="Mockup - iPhone"/>:''}
        {props.es?<PhoneEs alt="Mockup - iPhone" title="Mockup - iPhone"/>:''}
      </div>
    </>
  )
};

export default Phone